// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

// Common Layout
// import Layout from "./component/common/App";
import Landing from './home/Landing';

import * as serviceWorker from './serviceWorker';

class Root extends Component{
    render(){
        return(
            <Landing />
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();