import React, { Component } from "react";
import { FiMail } from "react-icons/fi";
// import FormStyle from "./FormStyle";

class ContactOne extends Component{
    render(){
        return(
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50 mt--50">
                                {/* <span className="subtitle">Say Hi</span> */}
                                <h2 className="title">Say Hi.</h2>
                                <p>Drop us an email to explore how we can help</p>
                                <div className="im_address_inner">
                                    <div className="im_address mt--5">
                                        <FiMail />
                                        <a className="link im-hover" href="mailto:mail@blockatlas.io">mail@blockatlas.io</a>
                                    </div>
                                </div>
                            </div>
                            {/* <FormStyle /> */}
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <img src="/assets/images/about/contact-phone.jpg" alt="trydo"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactOne;