import React from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
// import { Link } from 'react-router-dom';
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
// import TabTwo from "../elements/tab/TabTwo";
import ContactOne from "../elements/contact/ContactOne";
// import PortfolioList from "../elements/portfolio/PortfolioList";
import ServiceList from "../elements/service/ServiceList";
// import BlogContent from "../elements/blog/BlogContent";
import Helmet from "../component/common/Helmet";

const SlideList = [
    {
        textPosition: 'text-left',
        category: 'Design, build and execute with experts in Web 3',
        title: 'BLOCK ATLAS',
        description: '',
        buttonText: '',
        buttonLink: ''
    }
]
const Landing = () => {
    let title = 'About Us';
    let description1 = 'Our team has more than a decade of experience in creating digital products and technical solutions in the art, games and financial industries.';
    let description2 = 'We\'ve been involved in blockchain / Web3 for over 5 years, helping projects and businesses execute on their entry and expansion in this space.';

    return (
        <div>
            <Helmet pageTitle="Block Atlas" />
            <HeaderThree homeLink="/" logo="symbol-dark" color="color-black"/>
            {/* Start Slider Area   */}
            <div id="home" className="fix">
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value , index) => (
                        <div className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_home_img" key={index}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.title ? <h1 className="title theme-color" dangerouslySetInnerHTML={{__html: value.title}}></h1> : ''}
                                            {value.category ? <span className="theme-color font-700">{value.category}</span> : ''}
                                            {/* {value.description ? <p className="description">{value.description}</p> : ''} */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
            </div>
            {/* End Slider Area   */} 

            {/* Start Service Area  */}
            <div id="service" className="fix">
                <div className="service-area creative-service-wrapper ptb--120 bg_color--5" >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30 mb_sm--0">
                                    {/* <span className="subtitle">What I can do for you</span> */}
                                    <h2 className="title">Our Services</h2>
                                    <p>We help clients with all aspects of delivering quality products <br /> in the web3 and blockchain space.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <ServiceList item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
            {/* End Service Area  */} 
            
            {/* Start About Area */}
            <div id="about" className="fix">
                <div className="about-area pb--120 bg_color--5">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/dev-team.jpg" alt="About Team"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            {/* <span className="subtitle">My About Details</span> */}
                                            <h2 className="title">{title}</h2>
                                            <p className="description mt_dec--20">{description1}</p>
                                            <p className="description mt_dec--20">{description2}</p>
                                            <p className="description mt_dec--20">We work with clients globally in Asia, Australia, U.S. and Europe.</p>
                                            <h4 className="about_subtitle">Projects</h4>
                                            <p className="description">NFT - Collectible Digital Art, Fashion, Wallet Gallery, Marketplace, DAO</p>
                                            <p className="description mt_dec--30">Finance - Payment Gateway, Token Exchange, DeFi</p>
                                            <p className="description mt_dec--30">Games - On-chain Credit, DAO, Virtual Asset Collection/Swap</p>
                                            <h4>Platforms</h4>
                                            <p className="description">Ethereum, Polygon (Matic), xDai, Ethereum L2 & EVM compatible chains</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* End About Area */}

            {/* Start Portfolio Area */}
{/* 
            <div id="portfolio" className="fix">
                <div className="portfolio-area pb--120 bg_color--5">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center mb--30 mb_sm--0">
                                        <span className="subtitle">My complete project</span>
                                        <h2 className="title">My Latest Project</h2>
                                        <p>There are many variations of passages of Lorem Ipsum available, <br/> but the majority have suffered alteration.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList styevariation="text-center mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="6" />
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="view-more-btn mt--60 mt_sm--30 text-center">
                                        <a className="btn-default" href="/portfolio"><span>View Projects</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
             */}
            {/* End Portfolio Area */}            

            {/* Start COntact Area */}
            <div id="contact" className="fix">
                <div className="rn-contact-area pb--120 bg_color--5">
                    <ContactOne />
                </div>
            </div>
            {/* End COntact Area */}

            <FooterTwo />
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
        </div>
    )
}

export default Landing;
