import React ,{ Component }from "react";
import { FiFastForward , FiLayers , FiUsers , FiMonitor, FiCrosshair, FiCodesandbox } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiCrosshair />,
        title: 'Business & Product Strategy',
        description: 'Go to market, technical and operational needs, and competitor analysis for blockchain products.'
    },
    {
        icon: <FiLayers />,
        title: 'Smart Contract & Protocols',
        description: 'Analyse and build smart contracts for NFTs, DAOs, DEXs and other protocols.'
    },
    {
        icon: <FiMonitor />,
        title: 'Dapp UI Development',
        description: 'Design and build front-end interfaces for your users to interact with the blockchain'
    },
    { 
        icon: <FiFastForward />,
        title: 'Quality & DevOps',
        description: 'Full-stack testing and devops capability implementation to ensure quality product builds.'
    },
    {
        icon: <FiUsers />,
        title: 'Marketing Strategy',
        description: 'Branding, engagement and content strategy for web3 product and communities.'
    },
    { 
        icon: <FiCodesandbox />,
        title: 'Backend & API Development',
        description: 'Design and build suitable APIs and services to support rich user experiences.'
    }
]


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row service-main-wrapper">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            {/* <a href="/service-details"> */}
                                <div className="service service__style--2 text-left bg-gray">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            {/* </a> */}
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
